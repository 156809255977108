<template>
  <div>
    <DataTable
      :key="filtersHash"
      controller="inRoute"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="date"
      :sort-desc="true"
      search-title="Найти команду"
      :search-fields="searchFields"
      :loading="updating"
      @update:table-options="tableUpdated"
    >
      <template #actions>
        <v-btn @click="openCreateDialog()">Добавить команду</v-btn>
      </template>

      <template v-slot:[`item.logo`]="{item}">
        <img :src="item.logo" style="width: 60px" />
      </template>
      <template v-slot:[`item.name`]="{item}">
        {{ item.name }}
        <div class="team-id">ID: {{ item.teamId }}</div>
      </template>
      <template v-slot:[`item.link`]="{item}">
        {{ getUrl(item.teamId) }}
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-icon class="mr-2" @click="tableRowClicked(item)">mdi-pencil</v-icon>
        <v-icon
          v-if="item.state === 'Disabled'"
          class="mr-2"
          icon
          color="red"
          @click="enableTeam(item.teamId)"
          >
          mdi-power
        </v-icon>
        <v-icon
          v-if="item.state !== 'Disabled'"
          class="mr-2"
          icon
          color="green"
          @click="disableTeam(item.teamId)"
          >
          mdi-power
        </v-icon>
      </template>
    </DataTable>

    <TeamEditorDialog
      v-if="teamEditorDialogShow"
      :save-func="afterSave"
      :object-id="teamId"
      :object="team"
      @closed="teamEditorDialogShow = false"
    />
    
    <v-dialog v-model="dialog.opened" width="720">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.text">
        </v-card-text>
        <v-card-actions>
          <v-btn @click="hideError">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import TeamEditorDialog from './TeamEditorDialog.vue';
import scountApiInitializer from '@/api/scountApi';

export default {
  name: 'TeamsView',
  components: { TeamEditorDialog },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  data: () => ({
    dialog: {
      opened: false,
      title: undefined,
      text: undefined
    },
    filtersHash: Date.now(),
    headers: [
      { value: 'logo', text: 'Лого', align: 'center', width: 80, sortable: false },
      { value: 'name', text: 'Название', align: 'start', sortable: true },
      { value: 'link', text: 'Ссылка на регистраию', align: 'start', sortable: false },
      { value: 'actions', text: 'Действия', align: 'end', sortable: false },
    ],
    items: [],
    total: 0,
    searchFields: [
      { key: 'name', label: 'Наименование' },
    ],
    updating: false,
    teamId: undefined,
    team: undefined,
    teamEditorDialogShow: false,
    teamFromTempalteDialogShow: false,
  }),

  methods: {
    async tableUpdated(pagination, sorting, search) {
      await this.getTeams(pagination, sorting, search);
    },
    async disableTeam(teamId) {
      await this.scountApi.disableTeam(teamId);
      this.filtersHash = Date.now();
    },

    async enableTeam(teamId) {
      await this.scountApi.enableTeam(teamId);
      this.filtersHash = Date.now();
    },

    async getTeams(pagination, sorting, search) {
      if (!this.scountApi) return;

      this.updating = true;

      try {
        const response = await this.scountApi.getTeams(
          pagination,
          sorting,
          search,
        );

        const {values, total} = response.data;

        this.total = total;
        this.items = values;
      } finally {
        this.updating = false;
      }
    },

    getUrl(teamId) {
      const devSlag = location.host.indexOf('.rr-dev.') > -1 || location.host.indexOf('localhost') > -1 ? '.rr-dev' : '';

      // TODO: вынести в единое место
      const pairs = [
        { marketingProgramId: 'a6a2d18f-940d-4237-b99e-ec2d81281689', domain: `https://runningmr${devSlag}.ru/` },
        { marketingProgramId: '8381a34e-c84a-4dd4-a636-fdc2f3cbab5b', domain: `https://vskgo${devSlag}.ru/` },
        { marketingProgramId: '5696b784-5be8-4f76-8e5c-df084275c27d', domain: `https://mgoprof${devSlag}.ru/` },
        { marketingProgramId: 'bec0c390-4d39-44fb-86d7-fe65c3b89883', domain: `https://mneposilam${devSlag}.ru/` },
        { marketingProgramId: 'f94c9680-ad60-4636-b9ed-f32ae5282622', domain: devSlag ? `https://shag${devSlag}.ru/` : 'https://shag.scount.pro/' },
        { marketingProgramId: '9b5fe464-2c55-4466-bea0-abe9d5d6cfc1', domain: devSlag ? `https://transformation${devSlag}.ru/` : 'https://зожтрансформация.рф/' },
        { marketingProgramId: 'f3a88565-1657-4779-ae47-b3cc0287fc13', domain: devSlag ? `https://farmeco${devSlag}.ru/` : 'https://фармчемпионат.рф/' },
        { marketingProgramId: 'af0f9923-f448-413c-ba01-e09cebcb95f6', domain: devSlag ? `https://auchan${devSlag}.ru/` : 'https://auchan.scount.pro/' },
        { marketingProgramId: '37f15c6d-308f-4fb8-bc45-50a1634439b7', domain: devSlag ? `https://superliga78${devSlag}.ru/` : 'https://superliga78.scount.pro/' },
        { marketingProgramId: 'e4f19024-2e86-42ed-9f5d-be99c0621d71', domain: devSlag ? `https://clubs50${devSlag}.ru/` : 'https://clubs50.scount.pro/' },
        { marketingProgramId: '3deeebdd-b363-4ce4-9f79-de3713a52ff5', domain: devSlag ? `https://minsport${devSlag}.ru/` : 'https://minsport.scount.pro/' },
        { marketingProgramId: '42cce763-abc3-490e-a461-1568d354951d', domain: devSlag ? `https://expo${devSlag}.ru/` : 'https://expo.scount.pro/' },
        { marketingProgramId: 'dfeaffb5-56a3-4135-8345-3625d24c6b4c', domain: devSlag ? `https://rrexpo${devSlag}.ru/` : 'https://rrexpo.scount.pro/' },
        { marketingProgramId: '4d008edc-17d1-4669-8bd8-10221c98586a', domain: devSlag ? `https://severstal${devSlag}.ru/` : 'https://severstal.scount.pro/' },
        { marketingProgramId: '537acce1-f980-4cdf-b5fd-850a16be085b', domain: devSlag ? `https://demo${devSlag}.ru/` : 'https://demo.scount.pro/' },
      ];

      const host = pairs.find(x => x.marketingProgramId === this.mp.marketingProgramId).domain;

      return `${host}?t=${btoa(teamId)}`;
    },

    afterSave(model) {
      if (model.success)
        this.filtersHash = Date.now();
      else
        this.showError('Ошибка при создании шаблона', this.getAfterSaveText(model.error));
    },

    getAfterSaveText(error) {
        const e = error;
        if (!e) return 'Произошла ошибка';
        if (e.code === 409) return '';
        return `Уже есть команда с таким названием`;
    },

    openCreateDialog() {
      this.teamId = undefined;
      this.team = undefined;
      this.teamEditorDialogShow = true;
    },

    openEditDialog(team) {
      this.teamId = team.teamId;
      this.team = team;
      this.teamEditorDialogShow = true;
    },

    showError(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    hideError() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.opened = false;
    },

    tableRowClicked(team) {
      this.goToTeam(team.teamId);
    },

    goToTeam(teamId) {
      this.$router.push(`/team/${teamId}`);
    },

    goToTeams() {
      this.$router.push('/teams');
    }
  },
}
</script>

<style scoped>
.team-id {
  color: #999;
  font-size: 80%;
}
</style>